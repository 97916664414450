import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const Title = makeShortcode("Title");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<Caption>`}</inlineCode>{` component is typically used below images or videos. They will
default to 4 columns wide unless placed inside a `}<inlineCode parentName="p">{`<Row>`}</inlineCode>{` component. In this
case, it will assume the full width of its containing column.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Title mdxType="Title">Normal</Title>
    <img {...{
      "src": "images/design-for-ai-art.png",
      "alt": "Colors"
    }}></img>
    <Caption mdxType="Caption">
  This is a regular caption. It will attempt to respond to it’s container
  element appropriately.
    </Caption>
    <Title mdxType="Title">Full-width</Title>
    <img {...{
      "src": "images/design-for-ai-art.png",
      "alt": "Colors"
    }}></img>
    <Caption fullWidth mdxType="Caption">
  This is a full width caption. With this prop, the caption’s width will be 100%
  so it will fill its container.
    </Caption>
    <h2>{`Code`}</h2>
    <Title mdxType="Title">Normal</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/Caption/Caption.js src= https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Caption",
        "path": "components/Caption/Caption.js",
        "src": "",
        "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Caption": true
      }}>{`<Caption>
  This is a regular caption. It will attempt to respond to it’s container
  element appropriately.
</Caption>
`}</code></pre>
    <Title mdxType="Title">Full</Title>-width
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/Caption/Caption.js src= https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Caption",
        "path": "components/Caption/Caption.js",
        "src": "",
        "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Caption": true
      }}>{`<Caption fullWidth>


This is a full width caption. With this prop, the caption’s width will be 100%
so it will fill its container.

</Caption>

`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fullWidth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to full width`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add custom class name`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      